import { useContext, useEffect, useState } from "react";
import { IGetIsCreatingEvaluationsResponse, IPeriod } from "../../Interface/ApiInterface";
import { HeaderTitleDos } from "../../components/atoms/EvaluationHeader/EvaluationHeaderStyles";
import PaginationTable from "../../components/atoms/PaginationTable";
import ModalPerioEvaluacion from "../../components/common/ModalPerioEvaluacion";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { usePeriods } from "../../redux/Periods";
import {
  BackWhiteQ,
  ContainPeriodoFix,
  ContainerTable,
  ContainerTableFixed,
  PaginationBox,
  WrapperPeriodos,
  WrapperPeriodosBody,
  WrapperPeriodosHeader,
} from "./Dashboard/stylesDashboard/AdminStyles";
import {
  CellHeadingText,
  ContainPageQuestionaire,
  RowBox,
  Title
} from "./Objectives/ObjetivesStyles.js";
import { SocketContext } from "../../context/SocketContext";
import ModalLoader from "../../components/organisms/ConfirmationModals/ModalLoader";
import { useLoader } from "../../context/LoaderContext";
import colladoLoader from '../../assets/colladoLoader.gif';

const STORAGE_KEY = 'evaluationsInProgress';

const PeriodosEvaluaciones = () => {
  const { User } = useCollaboratorRedux();
  const [period, setPeriod] = useState<IPeriod>({} as IPeriod);
  const [submitModal, setSubmitModal] = useState(false);
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [periodId, setPeriodId] = useState(0);
  const [inProgressPeriods, setInProgressPeriods] = useState<number[]>(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : [];
  });

  const {
    Meta,
    periods,
    handlePostPeriod,
    handleGetAllPeriods,
    setErrorPost,
    setSuccesPost,
    errorPost,
    succesPost,
    message,
    handleCreateEvaluationPeriods,
  } = usePeriods();

  const { socket } = useContext(SocketContext);
  const { isLoading } = useLoader();

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(inProgressPeriods));
  }, [inProgressPeriods]);

  useEffect(() => {
    if (submitModal) {
      handlePostPeriod(period, User.claTrab);
      handleGetAllPeriods('');
      setPeriod({
        ...period,
        evaluationPeriodYear: 0,
        evaluationPeriodName: "",
      });
      setSubmitModal(false);
    }
  }, [submitModal]);

  useEffect(() => {
    handleGetAllPeriods('');
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit('join-periods');

      socket.on('finished-evaluations-process', () => {
        setInProgressPeriods(prev => {
          const updated = prev.filter(id => id !== periodId);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
          return updated;
        });
        handleGetAllPeriods('');
      });

      socket.on('canceled-evaluations-process', () => {
        setInProgressPeriods(prev => {
          const updated = prev.filter(id => id !== periodId);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
          return updated;
        });
      });

      return () => {
        socket.off('finished-evaluations-process');
        socket.off('canceled-evaluations-process');
      };
    }
  }, [socket, periodId]);

  const activeCautionModal = (item: IPeriod) => {
    if (item.evaluationsObjectives === 0) {
      return;
    }
    setPeriodId(item.evaluationPeriodId);
    setShowModalCaution(true);
  };

  const handleEmitFinishedEvaluation = () => {
    socket?.emit('finished-evaluations');
  };

  const handleIsInProgress = (periodId: number) => {
    return inProgressPeriods.includes(periodId);
  };

  const handleCreateEvaluation = async () => {
    setInProgressPeriods(prev => [...prev, periodId]);

    try {
      await handleCreateEvaluationPeriods(periodId, User.claTrab);
      handleEmitFinishedEvaluation();
      await handleGetAllPeriods('');
    } catch (error) {
      setInProgressPeriods(prev => prev.filter(id => id !== periodId));
      console.error('Error creating evaluations:', error);
    }
  };

  const { evaluationAttribute } = useCollaboratorRedux();

  const isButtonDisabled = (item: IPeriod) => {
    return (
      (item.evaluationsObjectives === 0 && item.evaluationPeriodYear <= new Date().getFullYear()) ||
      item.evaluationsForCreate === 0 ||
      handleIsInProgress(item.evaluationPeriodId)
    );
  };

  const getButtonStyle = (item: IPeriod) => {
    if (item.evaluationsObjectives === 0 && item.evaluationPeriodYear <= new Date().getFullYear()) {
      return {
        background: "#c1c1c3",
        color: "white",
        cursor: "default",
        border: 'none',
        minWidth: '182px',
      };
    }
    return {
      border: 'none',
      minWidth: '182px',
    };
  };

  return (
    <ContainPageQuestionaire>
      <HeaderTitleDos>Períodos y evaluaciones</HeaderTitleDos>
      <RowBox>
        <Title style={{ fontSize: "20px", marginBottom: "1rem" }}>
          Periodo
        </Title>
      </RowBox>
      <ContainPeriodoFix>
        {evaluationAttribute.create_period === 1 && (
          <ModalPerioEvaluacion
            setPeriod={setPeriod}
            period={period}
            setSubmitModal={setSubmitModal}
          />
        )}
        {succesPost && (
          <ModalStatusSuccessful
            handleClick={setSuccesPost}
            modalStatus={succesPost}
            messageShow={true}
            message={message}
            titleModal={message === "El periodo ha sido creado correctamente." ? "Periodo creado" : "Evaluación creada"}
          />
        )}
        {isLoading && <ModalLoader />}
        {errorPost && (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow={true}
            message={message}
            titleModal="Faltan objetivos generales para crear evaluaciones"
          />
        )}
      </ContainPeriodoFix>
      <BackWhiteQ>
        <ContainerTableFixed>
          <ContainerTable>
            <WrapperPeriodos>
              <WrapperPeriodosHeader>
                <div className="first">
                  <CellHeadingText>Año</CellHeadingText>
                </div>
                <div className="second">
                  <CellHeadingText>Período</CellHeadingText>
                </div>
                <div className="third">
                  <CellHeadingText>Evaluaciones creadas</CellHeadingText>
                </div>
                <div className="fourth">
                  <CellHeadingText>Evaluaciones por crear</CellHeadingText>
                </div>
                {evaluationAttribute.create_evaluations_button === 1 && (
                  <div className="fifth">
                    <CellHeadingText>Acción</CellHeadingText>
                  </div>
                )}
              </WrapperPeriodosHeader>

              {periods.map((item: IPeriod) => (
                <WrapperPeriodosBody key={item.evaluationPeriodId}>
                  <div className="first">{item.evaluationPeriodYear}</div>
                  <div className="second">
                    {item.evaluationPeriodNumberOfYear}
                  </div>
                  <div className="third">{item.evaluationsCreates}</div>
                  <div className="fourth">{item.evaluationsForCreate}</div>

                  {evaluationAttribute.create_evaluations_button === 1 && (
                    <div className="fifth">
                      <button
                        className="btn"
                        disabled={isButtonDisabled(item)}
                        onClick={() => activeCautionModal(item)}
                        style={getButtonStyle(item)}
                      >
                        {handleIsInProgress(item.evaluationPeriodId) ? (
                          <>
                            En progreso <img style={{ width: "21px" }} src={colladoLoader} alt="" />
                          </>
                        ) : (
                          'Crear evaluaciones'
                        )}
                      </button>
                    </div>
                  )}
                </WrapperPeriodosBody>
              ))}
            </WrapperPeriodos>
          </ContainerTable>
          <PaginationBox style={{ marginTop: "40px" }}>
            <PaginationTable table="Periods" Meta={Meta} />
          </PaginationBox>
        </ContainerTableFixed>
      </BackWhiteQ>
      <Caution
        textBody="Al hacer clic en Continuar, estarás cerrando oficialmente el periodo anterior y comenzando uno nuevo para las evaluaciones. Ten en cuenta que esta acción no se puede deshacer."
        textBodyQuestion="¿Deseas continuar?"
        textBodyOk=""
        messageShow={true}
        title="Crear evaluaciones"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={handleCreateEvaluation}
      />
    </ContainPageQuestionaire>
  );
};

export default PeriodosEvaluaciones;
