import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalTableProps } from "../../../Interface/ApiInterface";
import evalCheck from "../../../assets/icons/eval-check.svg";
import evaluado from "../../../assets/icons/eval.svg";
import finishMoreActions from "../../../assets/icons/finishMoreActions.svg";
import finishMoreActionsDisabled from "../../../assets/icons/finishMoreActionsDisabled.svg";
import saveMoreActions from "../../../assets/icons/saveMoreActions.svg";
import singMoreActions from "../../../assets/icons/singMoreActions.svg";
import singMoreActionsDisabled from "../../../assets/icons/singMoreActionsDisabled.svg";
import { evaluationNullPolitics } from "../../../common/utils";
import Caution from "../../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../../context/EvaluacionContext/NewEvaluationContext";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { SocketContext } from "../../../context/SocketContext";
import { useSaveButton, useUrlPermisos } from "../../../hooks/Evaluation/index";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { RootReducerInterface } from "../../../redux/store";
import { getObjectivesPreviewListFuture } from "../../../services/dataSelects.service";
import {
  saveEvaluationServices,
  updateEvaluationServices,
  updateEvaluationServicesPrueba,
} from "../../../services/evaluation.service";
import { ButtonActions } from "./Button";
import { ContainerButtons, ContainerEvaluators, FirmsBox } from "./FirmsStyles";
import {
  EvaluationUpdated,
  arrayObjCurrents,
  arrayObjDataSaved,
  arrayObjDefault,
  arrayObjEbita,
  arrayObjFuture,
} from "./utils/objectsUpdate";

export const Firms = ({
  evaluation,
  futures,
  setEvaluation,
  oportunidad,
  fortalezas,
  mensajeError,
  datosFuturosFiltradosDos,
  futuresObjetivesId,
  setFooter,
  desempenioValue,
  valuesFuturesG,
  sumaTotal,
  setReload,
  reload
}: ModalTableProps) => {
  //** Context Values
  const { setFutureObjetives, evaluationInfo, setIsReduxOrContext } =
    useContext(EvaluationContext) as EvaluationContextType;

  // console.log("evaluationInfo ->", mensajeError);
  // const { socket, online, idRoom } = useContext(SocketContext);

  const filtrosAlmacenados = localStorage.getItem("principalFilters");
  const filtros = JSON.parse(filtrosAlmacenados ?? "{}");
  const { year, periodo, propLocation, propDirector } = filtros;

  //** Context Validations
  const {
    validateFutureObjs,
    validateFinishEv,
    validateFieldsFutureObjs,
    validationFortalezasSection,
    validateEmptyInput,
    objetivesCurrents,
    // validateComments,
  } = useContext(NewEvaluationContext) as NewEvaluationContextType;

  //** Redux
  const {
    hanldeUpdateEvaluation,
    handleGetEvaluation,
    handleSetEvaluationFirm,
    hanldeSaveEvaluation,
    Evaluation,
  } = useEvaluationRedux();
  const { handleGetCollaborator } = useCollaboratorRedux();
  const { evaluationAttribute, User } = useCollaboratorRedux();

  const { Evaluation: EvaluationNew } = useSelector(
    (state: RootReducerInterface) => state.Evaluations
  );

  //** Custom hooks
  const { activeSave } = useSaveButton();
  const [idLastObjetives, setIdLastObjetives] = useState<any>([]);
  const [idObjetivesI, setIdObjetivesI] = useState<any>([]);
  const [idObjetivesG, setIdObjetivesG] = useState<any>([]);
  const { urlPermisos } = useUrlPermisos();
  // const { isActualDate } = useSemesterYearNow();

  //** validate BtnFinish
  const [ableFinish, setAbleFinish] = useState(false);
  //** validate BtnFinish

  //** hooks
  const [evaluationCopy, setEvaluationCopy] = useState({ ...evaluation });
  const [semesterFuture, setSemesterFuture] = useState<number | null>(null);
  const [typeUser, setTypeUser] = useState("");
  const [show, setShow] = useState(false);

  const [succesPost, setSuccesPost] = useState(false);
  const [errorPost, setErrorPost] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [messageError, setMessageError] = useState("");

  const handleClose = () => setShow(false);
  const navigate = useNavigate();

  useEffect(() => {
    //?? OBJETIVOS EXCEPTO LOS TYPE D
    const getData = async () => {
      const { data } = await getObjectivesPreviewListFuture(
        evaluationInfo.employeeEvaluation.evaluationPeriodId,
        evaluationInfo?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
        evaluationInfo?.employeeEvaluation.employeeEvaluationId
      );
      const futureOnlyD = data.filter((item: any) => item.type === "D");

      const futureOnlyI = data.filter((item: any) => item.type === "I");
      const futureOnlyG = data.filter((item: any) => item.type === "G");
      setIdObjetivesI(futureOnlyI);
      setIdLastObjetives(futureOnlyD);
      setIdObjetivesG(futureOnlyG);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (evaluation && semesterFuture === null) {
      if (evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear === 1) {
        setSemesterFuture(2);
      } else {
        setSemesterFuture(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);
  //?? OBJETIVOS EXCEPTO LOS TYPE G

  const allIds = [...futuresObjetivesId, ...idLastObjetives];


  //** Handlers
  const handleFinhisEvaluation = async (e: any, updateOrSave?: boolean) => {
    e.preventDefault();

    if (mensajeError) {
      setErrorPost(true);
      return;
    } else {
      const dataEbita = arrayObjEbita(
        EvaluationNew?.employeeEvaluationObjetivesFutures,
        valuesFuturesG,
        evaluation,
        semesterFuture,
        idObjetivesG
      );

      // console.log("<SAVE EVALUATION>", dataEbita, valuesFuturesG,);

      const dataSendExtraUno = arrayObjDefault(
        datosFuturosFiltradosDos,
        evaluation,
        allIds,
        semesterFuture,
        desempenioValue
      );

      //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! revisar dataSaved

      const dataSaved = arrayObjDataSaved(
        futures,
        idObjetivesI,
        evaluation,
        semesterFuture
      );

      // const filterObjetive = dataSaved.filter((item: any) => item !== null);

      if (dataSaved.length > 0) {
        //!! +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        const currents = arrayObjCurrents(
          evaluation?.employeeEvaluationObjetivesCurrents,
          objetivesCurrents,
          evaluation,
          sumaTotal
        );

        // Recorrer los objetivos actuales y calcular el % de alcance
        const currentsWithPercentage = currents.map((obj: any) => {
            const percentageReach = (obj.employeeEvaluationObjetivesReal / obj.employeeEvaluationObjetivesGoal) * 100;

            return {
                ...obj,
                // Usar Math.round para redondear correctamente
                employeeEvaluationObjetivesPercentageReach: Math.round(percentageReach).toString(),
            };
        });
        // console.log("currents ->", currents);

        //!! +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        const dataFuture = [...dataEbita, ...dataSaved, ...dataSendExtraUno];

        const updateEvaluation = EvaluationUpdated(
          evaluation,
          currentsWithPercentage,
          dataFuture,
          oportunidad,
          fortalezas,
          evaluationNullPolitics,
          urlPermisos,
          Evaluation.catStatusEvaluationId
        );

        //!! +++++++++++++++++++++++++Actualizar datos+++++++++++++++++++++++++

        const objetivosActualizadosFront = arrayObjFuture(dataFuture);

        //!! +++++++++++++++++++++++++Actualizar datos+++++++++++++++++++++++++

        if (updateOrSave) {
          //* Con esto le decimos al componente "TableGoalValue"
          //* que pase los objetivos del context y no del redux
          setIsReduxOrContext(false);

          setTimeout(() => {
            setEvaluation(evaluationCopy);
            handleGetCollaborator(evaluation.employeeEvaluationEvaluatedUserId, periodo);
            handleGetEvaluation(
              evaluation?.employeeEvaluationEvaluatorUserId,
              evaluation?.employeeEvaluationEvaluatedUserId,
              evaluation?.evaluationPeriod.evaluationPeriodId
            );


          }, 2000);

          // console.log("Se guarda -> ", updateEvaluation);
          // handleGetCollaborator(evaluation.employeeEvaluationEvaluatedUserId);

          hanldeSaveEvaluation(updateEvaluation);
          saveEvaluationServices(updateEvaluation)
            .then((res) => {
              // console.log(res);
              // if (res.data) {
              // console.log("Entro aquí success");
              setSuccesPost(true);
              setSuccess(true);
              window.scrollTo(0, 0);
              setReload(!reload);
              // if (online && socket && idRoom)
              //   socket.emit("new-information", idRoom);
              // }
            })
            .catch((err) => {
              // console.log(err);
              // console.log("Entro aquí error");
              setError(true);
              setMessageError("Error al guardar la evaluación");
              setErrorPost(true);
              window.scrollTo(0, 0);
            });

          const { data } = await getObjectivesPreviewListFuture(
            evaluationInfo.employeeEvaluation.evaluationPeriodId,
            evaluationInfo?.employeeEvaluation
              .employeeEvaluationEvaluatedUserId,
            evaluationInfo?.employeeEvaluation.employeeEvaluationId
          );

          setFutureObjetives([...objetivosActualizadosFront]);
        } else {
          //* Con esto le decimos al componente "TableGoalValue"
          //* que pase los objetivos del context y no del redux
          setIsReduxOrContext(false);

          setTimeout(() => {
            setEvaluation(evaluationCopy);
            handleGetCollaborator(evaluation.employeeEvaluationEvaluatedUserId, periodo);
            handleGetEvaluation(
              evaluation?.employeeEvaluationEvaluatorUserId,
              evaluation?.employeeEvaluationEvaluatedUserId,
              evaluation?.evaluationPeriod.evaluationPeriodId
            );
          }, 2000);

          // console.log("Se guarda -> ", updateEvaluation);
          hanldeUpdateEvaluation(updateEvaluation);
          updateEvaluationServices(updateEvaluation)
            .then((res) => {
              setSuccesPost(true);
              setSuccess(true);
              window.scrollTo(0, 0);

              if (!urlPermisos) {
                if (User.role !== "Evaluado") {
                  setTimeout(() => {
                    navigate("/Dashboard");
                  }, 2500);
                }
              }

              // if (online && socket && idRoom)
              //   socket.emit("new-information", idRoom);
            })
            .catch((err) => {
              setError(true);
              setMessageError("Error al actualizar la evaluación");
              setErrorPost(true);
              window.scrollTo(0, 0);
            });
          const { data } = await getObjectivesPreviewListFuture(
            evaluationInfo.employeeEvaluation.evaluationPeriodId,
            evaluationInfo?.employeeEvaluation
              .employeeEvaluationEvaluatedUserId,
            evaluationInfo?.employeeEvaluation.employeeEvaluationId
          );
          setFutureObjetives([...objetivosActualizadosFront]);
        }
      } else {
        setErrorPost(true);
      }
    }
  };

  const isEvaluado = User.role === "Evaluado";
  const isEvaluador = ["Evaluador", "Director", "Admin TI", "Admin", "Admin RH"].includes(User.role);

  const validateFirmButton = () => {
    if (isEvaluado) {
      return (
        evaluation?.employeEvaluationFimaEvaluated === null && // No ha firmado como evaluado
        Evaluation?.catStatusEvaluationId === 6 // Evaluación está pendiente de firmar
      );
    }

    if (isEvaluador) {
      return (
        evaluation?.employeEvaluationFimaEvaluator === null && // No ha firmado como evaluador
        Evaluation?.catStatusEvaluationId === 6 // Evaluación está pendiente de firmar
      );
    }

    return false; // Por defecto, el botón permanece deshabilitado
  };

  const isAdminOrDirector = ["Admin TI", "Admin RH", "Director"].includes(User.role);
  // console.log("<AutoEvaluacion", User.role, urlPermisos);
  // console.log(valuesFuturesG, "valuesFuturesG", EvaluationNew);

  const handleFirm = async (e: any, type: string) => {
    e.preventDefault();
    setTypeUser(type);
    setShow(true);
  };

  // console.log(EvaluationNew);

  const handleFinishFirm = async () => {
    const dataEbita = arrayObjEbita(
      EvaluationNew?.employeeEvaluationObjetivesFutures,
      valuesFuturesG,
      evaluation,
      semesterFuture,
      idObjetivesG
    );
    const dataSendExtraUno = arrayObjDefault(
      datosFuturosFiltradosDos,
      evaluation,
      allIds,
      semesterFuture,
      desempenioValue
    );

    const dataSaved = arrayObjDataSaved(
      futures,
      idObjetivesI,
      evaluation,
      semesterFuture
    );

    const filterObjetive = dataSaved.filter((item: any) => item !== null);

    const dataFuture = [...dataEbita, ...filterObjetive, ...dataSendExtraUno];

    if (filterObjetive.length > 0) {
      const currents = arrayObjCurrents(
        evaluation?.employeeEvaluationObjetivesCurrents,
        objetivesCurrents,
        evaluation,
        sumaTotal
      );
      const updateEvaluation = EvaluationUpdated(
        evaluation,
        currents,
        dataFuture,
        oportunidad,
        fortalezas,
        evaluationNullPolitics,
        urlPermisos,
        Evaluation.catStatusEvaluationId
      );

      //!! +++++++++++++++++++++++++Actualizar datos+++++++++++++++++++++++++

      const objetivosActualizadosFront = arrayObjFuture(dataFuture);

      //!! +++++++++++++++++++++++++Actualizar datos+++++++++++++++++++++++++

      // console.log("<UPDATE EVALUATION>", updateEvaluation, dataFuture);

      handleGetCollaborator(evaluation.employeeEvaluationEvaluatedUserId, periodo);

      // const { data } = await getObjectivesPreviewListFuture(
      //   evaluationInfo.employeeEvaluation.evaluationPeriodId,
      //   evaluationInfo?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
      //   evaluationInfo?.employeeEvaluation.employeeEvaluationId
      // )
      // const futureOnlyG = data.filter((item: any) => item.type === "G")

      setFutureObjetives([...objetivosActualizadosFront]);

      // console.log("<FUTURE CARGADO AL ESTADO>", [
      //   ...objetivosActualizadosFront,
      // ]);

      // setTimeout(() => {
      setEvaluation(EvaluationNew);
      // hanldeUpdateEvaluation(updateEvaluation);
      const { data } = await updateEvaluationServicesPrueba(updateEvaluation);
      // console.log("<DATA>", data);
      if (data) {
        // setTimeout(() => {
        setSuccesPost(true);
        window.scrollTo(0, 0);
        handleClose();
        handleSetEvaluationFirm(evaluation.employeeEvaluationId, typeUser);
        setTimeout(() => {
          handleGetEvaluation(
            evaluation?.employeeEvaluationEvaluatorUserId,
            evaluation?.employeeEvaluationEvaluatedUserId,
            evaluation?.evaluationPeriod.evaluationPeriodId
          );
        }, 2000);
        // }, 2000);

      } else {
        setErrorPost(true);
        window.scrollTo(0, 0);
        handleSetEvaluationFirm(evaluation.employeeEvaluationId, typeUser);
        setTimeout(() => {
          handleGetEvaluation(
            evaluation?.employeeEvaluationEvaluatorUserId,
            evaluation?.employeeEvaluationEvaluatedUserId,
            evaluation?.evaluationPeriod.evaluationPeriodId
          );
        }, 2000);
      }

      // }, 2000)

      // setSuccesPost(true);
      // window.scrollTo(0, 0);
      // handleClose();
    }
  };

  // const handleShow = () => setShow(true);

  const handleSaveEvaluationBtn = (e: any) => {
    handleFinhisEvaluation(e, true);
  };

  const handleFinhisEvaluationBtn = (e: any) => {
    handleFinhisEvaluation(e);
  };

  const validateBtn = () => {
    //* validateFinishEv se encarga de verificar que los valores
    //* de la evaluación actual estén en 100%
    if (Evaluation?.catStatusEvaluationId === 7 || validateFinishEv) {
      setAbleFinish(true);
    }
    if (Evaluation?.catStatusEvaluationId !== 6) {
      if (!urlPermisos) {
        //? validateFutureObjs
        //** Si la suma de los objetivos futuros es 100 -> !true
        //? validateFieldsFutureObjs
        //** Si los campos cumplen con las historia h073
        // Se extrae !validateComments para que no se tome en cuenta
        if (
          !validateFutureObjs &&
          !validateFieldsFutureObjs &&
          !validationFortalezasSection &&
          !validateEmptyInput
        ) {
          setAbleFinish(true);
        } else {
          setAbleFinish(false);
        }
      }

      if (urlPermisos) {
        if (!validateFieldsFutureObjs) {
          setAbleFinish(true);
        } else {
          setAbleFinish(false);
        }
      }
    }

    if (Evaluation?.catStatusEvaluationId === 6) {
      if (!urlPermisos) {
        // console.log("evaluación", validateFutureObjs, validateFieldsFutureObjs, validationFortalezasSection, validateEmptyInput);

        //? validateFutureObjs
        //** Si la suma de los objetivos futuros es 100 -> !true
        //? validateFieldsFutureObjs
        //** Si los campos cumplen con las historia h073
        // Se extrae !validateComments para que no se tome en cuenta
        if (
          !validateFutureObjs &&
          !validateFieldsFutureObjs &&
          !validationFortalezasSection &&
          !validateEmptyInput
        ) {
          setAbleFinish(true);
        } else {
          setAbleFinish(false);
        }
      }
      if (urlPermisos) {
        // if (!validateFieldsFutureObjs && validateComments) {
        if (!validateFieldsFutureObjs) {
          setAbleFinish(true);
        } else {
          setAbleFinish(false);
        }
      }
    }
  };

  useEffect(() => {
    validateBtn();
    // console.log("Setean validate comments", Evaluation?.catStatusEvaluationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateFutureObjs,
    validateFinishEv,
    validateFieldsFutureObjs,
    validationFortalezasSection,
    urlPermisos,
    validateEmptyInput,
    // validateComments,
  ]);

  // console.log("<AQI>", message, errorMessages, success, error);

  // console.log("<AutoEvaluacion>", ableFinish, validateComments);

  return (
    <>
      <>
      {Evaluation?.catStatusEvaluationId &&
        Evaluation?.catStatusEvaluationId !== 6 &&
        Evaluation?.catStatusEvaluationId !== 7 && (
          <ContainerButtons>
            <ButtonActions
              activeSave={activeSave}
              marginBtm={"8px"}
              imageButton={activeSave ? saveMoreActions : saveMoreActions}
              txtButton={"Guardar"}
              handleAction={handleSaveEvaluationBtn}
            />
            <ButtonActions
              activeSave={!ableFinish}
              marginBtm={""}
              imageButton={
                !ableFinish ? finishMoreActionsDisabled : finishMoreActions
              }
              txtButton={"Terminar"}
              handleAction={handleFinhisEvaluationBtn}
            />
          </ContainerButtons>
        )}
        <>

      {/* Botones para evaluaciones completadas */}
      {Evaluation?.catStatusEvaluationId >= 6 && (
        <FirmsBox>
          {/* Mostrar el botón Guardar basado en el rol y el estado */}
          {isAdminOrDirector && Evaluation?.catStatusEvaluationId === 7 && (
            <ButtonActions
              marginBtm={"10px"}
              imageButton={saveMoreActions}
              txtButton={"Guardar"}
              handleAction={handleSaveEvaluationBtn}
            />
          )}



          {(isEvaluado || isEvaluador) && (
            <ButtonActions
              activeSave={!validateFirmButton()}
              marginBtm={"8px"}
              imageButton={
                validateFirmButton() ? singMoreActions : singMoreActionsDisabled
              }
              txtButton={"Firmar"}
              handleAction={(e: any) => handleFirm(e, isEvaluado ? "evaluated" : "evaluator")}
            />
          )}


          {/* Indicadores visuales de firma */}
          <div style={{ marginTop: "10px" }}>
            <ContainerEvaluators>
              <div className="containertxt">
                {evaluation?.employeEvaluationFimaEvaluated !== null ? (
                  <img src={evalCheck} alt="evaluado" />
                ) : (
                  <img src={evaluado} alt="evaluado" />
                )}
                Evaluado
              </div>
              <div className="containertxt">
                {evaluation?.employeEvaluationFimaEvaluator !== null ? (
                  <img src={evalCheck} alt="evaluador" />
                ) : (
                  <img src={evaluado} alt="evaluador" />
                )}
                Evaluador
              </div>
            </ContainerEvaluators>
          </div>
        </FirmsBox>
      )}

        </>
      </>
      <Caution
        textBody="Estás a punto de firmar la evaluación. Esta acción guardará todos los cambios realizados en el formulario. "
        textBodyQuestion="¿Quieres continuar?"
        textBodyOk=""
        messageShow={true}
        title="Firma de evaluación"
        estado={show}
        cambiarEstado={handleClose}
        textButtonAct="Firmar"
        textButtonClose="Cancelar"
        handleClick={handleFinishFirm}
      />

      {/* sustituir el modal */}
      {succesPost && (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message={
            success
              ? "Se ha guardado correctamente."
              : "Evaluación guardada con éxito"
          }
          setFooter={setFooter}
        />
      )}
      {errorPost && (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          messageShow
          message={
            error ? messageError : "Tienes un valor menos a 0 en tu evaluación"
          }
        />
      )}
    </>
  );
};
